<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template #default>
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="lightgrey"
                  small
                  label
                >
                  x
                </v-chip>
              </td>
              <td>
                El turno pertenece a otro servicio
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="red"
                  small
                  label
                  dark
                >
                  día
                </v-chip>
              </td>
              <td>
                Festivo
              </td>
            </tr>
            <tr>
              <td>
                <v-chip
                  color="orange"
                  small
                  label
                  dark
                >
                  día
                </v-chip>
              </td>
              <td>
                Fin de semana
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'

export default {
  mixins: [internalShowingMixin],
}
</script>
